<template>
  <!-- eslint-disable -->
  <div class="organizationManagement center-inner report">
    <div class="organizationManagement_title">{{ $route.meta.title }}</div>
    <!-- 头部 -->
    <div class="organizationManagement_top">
      <div class="organizationManagement_top_content">
        <div class="el_search">
          <div class="el_search_input">
            <el-select
              class="select"
              v-model="seachTitle"
              style="width: 108px; margin-right: 35px"
            >
              <el-option label="报告名称" value="reportName"></el-option>
              <el-option label="操作人" value="operator"></el-option>
            </el-select>

            <el-input
              style="width: 300px"
              placeholder="报告名称及操作人模糊搜索"
              v-model="name"
              clearable
            >
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
            <span class="el_font">报告</span>
            <el-select
              v-model="report"
              style="width: 190px"
              placeholder="请选择报告类型"
              clearable
            >
              <el-option
                v-for="item in options"
                :key="item.label"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
            <span class="el_font">生成时间</span>
            <!-- <el-date-picker
              value-format="yyyy-MM-dd"
              size="medium"
              class="picker-data"
              v-model="timer"
              type="daterange"
              range-separator="—"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker> -->
            <el-date-picker
              v-model="timer[0]"
              type="date"
              size="medium"
              value-format="yyyy-MM-dd"
              placeholder="开始日期">
            </el-date-picker>
            -
            <el-date-picker
              v-model="timer[1]"
              type="date"
              size="medium"
              value-format="yyyy-MM-dd"
              placeholder="结束日期">
            </el-date-picker>
          </div>
          <div class="el_search_button">
            <el-button
              type="primary"
              style="width: 60px"
              size="small"
              @click="Search"
              >查询</el-button
            >
            <el-button size="small" class="chongzhi" @click="Remake"
              >重置</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 主体 -->
    <div class="organizationManagement_content">
      <div class="organizationManagement_content_top">
        <div style="overflow: hidden">
          <el-button
            type="primary"
            size="small"
            class="xinzeng_z"
            @click="handel"
          >
            <img src="../../assets/img/views/addb.png" alt />
            <span style="margin-left: 5px">新增报告</span>
          </el-button>
        </div>
        <div class="tags" v-if="tags != ''">
          <span class="tags-title">条件：</span>
          <el-tag
            v-for="tag in tags"
            closable
            @close="handleClose(tag, tags)"
            :key="tag.name"
            :type="tag.type"
          >
            <span>{{ tag.text }}:</span>
            <span>{{ tag.value }}</span>
          </el-tag>
        </div>
        <!-- 编辑 -->
      </div>
      <div class="content_table">
        <div>
          <el-table
            v-loading="loading"
            v-if="activeName == 'first'"
            :data="tableData"
            stripe
            :header-cell-style="headerStyle"
            :cell-style="cellStyle"
            style="width: 100%; text-align: center; border: 1px solid #e4e6ec"
          >
            <el-table-column
              label="序号"
              type="index"
              :index="indexMethod"
              width="100"
            ></el-table-column>
            <el-table-column prop="reportName" label="报告名称">
              <template slot-scope="scope">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="scope.row.reportName"
                  placement="top"
                >
                  <!-- <div
                    :class="['name-wrapper',scope.row.reportType != '话题专项' ?'name-wrapper-color':'']"
                    @click="handle(scope.row)"
                  >{{ scope.row.reportName }}</div>-->
                  <div class="name-wrapper" @click="handle(scope.row)">
                    {{ scope.row.reportName }}
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column width="150" prop="reportType" label="报告类型"></el-table-column>
            <el-table-column label="报告时间">
              <template slot-scope="scope">
                <div>
                  {{scope.row.reportStartTime + "~" + scope.row.reportEndTime}}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="operator" label="操作人"></el-table-column>
            <el-table-column
              prop="generateTime"
              label="生成时间"
              width="150"
            ></el-table-column>
            <el-table-column label="操作" width="250">
              <template slot-scope="{ row }">
                <div class="table_handle">
                  <el-button @click="upDown(row)" class="bianji" type="text" :disabled="row.disabled">
                    {{row.disabled? "正在导出...": "下载导出" + "(" + row.isReport + ")次"}}
                  </el-button>
                  <div class="table_line"></div>
                  <el-button class="shanchu" type="text" @click="deletes(row)"
                    >删除</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <span class="total">
              共{{ tablePage.total }}条, 当前显示第{{
                tablePage.page * tablePage.pageSize - tablePage.pageSize + 1
              }}-{{
                tablePage.page * tablePage.pageSize > tablePage.total
                  ? tablePage.total
                  : tablePage.page * tablePage.pageSize
              }}条
            </span>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="tablePage.page"
              :page-sizes="tablePage.pageSizes"
              :page-size="tablePage.pageSize"
              layout="sizes, prev, pager, next,jumper"
              :total="tablePage.total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <deleteDialog @deleteFn="deleteFn" ref="openDialog" />
    <!-- 点击专项报告的弹框 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="closeing"
    >
      <span>话题专项报告请下载后查看!</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureLoad">下 载</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { List, deleteRecord } from "@/api/report/list.js";
import deleteDialog from "../../components/deleteDialog";
export default {
  data() {
    return {
      // 控制下载导出按钮
      loading: false,
      activeName: "first",
      headerStyle: {
        background: "#F6F7FA",
        textAlign: "center",
        fontSize: "16px",
        color: "#333",
      },
      cellStyle: {
        textAlign: "center",
      },
      // 开始时间
      reportStartTime: "",
      // 结束时间
      reportEndTime: "",
      // 模糊搜索
      // 报告名称操作人
      name: "",
      // 选择下拉 报告名称操作人
      seachTitle: "reportName",
      // 报告
      report: "",
      // 时间
      timer: [],
      //页数
      tablePage: {
        pageSizes: [10, 20, 30, 40, 50],
        page: 1,
        pageSize: 10,
        total: 0,
      },
      options: [
        {
          label: "日报",
        },

        {
          label: "月报",
        },

        {
          label: "自定义报告",
        },
        {
          label: "话题专项",
        },
      ],
      tags: [],
      tableData: [],
      dataList: {},
      // 点击专项报告时的弹框
      dialogVisible: false,
      specialId: {},
    };
  },
  created() {
    this.getList();
  },
  components: {
    deleteDialog,
  },
  methods: {
    closeing() {
      this.dialogVisible = false;
    },
    // 获取列表
    async getList(obj) {
      this.loading = true;
      let datas = {
        organizationId: this.$store.state.user.organizationId,
      };

      if (obj) {
        this.dataList = obj;
        this.dataList.organizationId = this.$store.state.user.organizationId;
        datas = this.dataList;
      } else if (Object.values(this.dataList).length > 0) {
        datas = this.dataList;
      }

      if (this.name != "") {
        datas[this.seachTitle] = this.name;
      }
      let url = `?pageNum=${this.tablePage.page}&pageSize=${this.tablePage.pageSize}`;

      const { data } = await List(datas, url);
      if (data != undefined) {
        this.tableData = data.rows;
        this.tablePage.total = data.total;
      }
      this.loading = false;
    },
    // 点击弹框确定
    sureLoad() {
      this.dialogVisible = false;
      this.upDown(this.specialId);
    },
    // 下载导出
    upDown(item) {
      item.disabled = true;
      let obj = {};
      // let time = this.$calcu.timeCycle(new Date(), "yyyy-MM-dd HH：mm");
      // let textName = this.$store.state.user.mechanism + "舆情" + item.reportType + "-" + item.operator + time + ".docx";
      if (item.reportType != "话题专项") {
        obj = {
          "url": "/api/report/report/downLoadReportStream",
          "method": "post",
          data: {
            reportId: item.reportId,
            organizationName: this.$store.state.user.mechanism,
            reportType: item.reportType,
            type: item.type,
            reportEndTime: item.reportEndTime,
            reportStartTime: item.reportStartTime,
          },
        };
        this.$parent.downLinkFile(obj);
      } else {
        obj = {
          "url": "/api/topic/word/download/" + item.reportId,
          "method": "get",
        };
        this.$parent.downLinkFile(obj);
      }
      item.disabled = false;
      setTimeout(() => {
        this.getList();
      }, 1000);
    },
    // 删除
    async deleteFn(item) {
      const res = await deleteRecord(item.reportId);
      if (res.data.code == 200) {
        this.$message({
          message: "删除成功！",
          type: "success",
        });
        if (this.tableData.length == 1) {
          this.tablePage.page = this.tablePage.page - 1;
        }
      } else {
        this.$message.error("删除失败！");
      }
      setTimeout(() => {
        this.getList();
      }, 1000);
    },
    deletes(item) {
      item.message = "此报告";
      this.$refs.openDialog.openDialog(item);
    },
    // 分页
    handleSizeChange(val) {
      this.tablePage.pageSize = val;
      this.tablePage.page = 1;
      this.getList();
      window.scrollTo(0, 0);
    },
    handleCurrentChange(val) {
      this.tablePage.page = val;
      this.getList();
      window.scrollTo(0, 0);
    },
    // 序号
    indexMethod(index) {
      return (
        index +
        this.tablePage.page * this.tablePage.pageSize -
        this.tablePage.pageSize +
        1
      );
    },
    //搜索
    Search() {
      console.log(this.timer);
      if(this.timer[0]&&this.timer[1]&&this.timer[0]>this.timer[1]){
        this.$message.warning("请选择正确的时间段");
        return;
      }
      if(!this.timer[0]||!this.timer[1]){
        this.timer = [];
      }
      this.tags = [];
      this.tablePage.page = 1;
      if (this.name != "") {
        this.tags.push({
          text: this.seachTitle == "reportName" ? "报告名称" : "操作人",
          value: this.name,
        });
      }
      if (this.report != "") {
        this.options.forEach((ite) => {
          if (ite.label == this.report) {
            this.tags.push({
              text: "报告",
              value: ite.label,
            });
          }
        });
      }
      if (this.timer != false&&this.timer[0]&&this.timer[1]) {
        this.reportStartTime = this.timer[0];
        this.reportEndTime = this.timer[1];
        this.tags.push({
          text: "开始时间",
          value: this.reportStartTime,
        });
        this.tags.push({
          text: "结束时间",
          value: this.reportEndTime,
        });
      }
      let data = {};
      if (this.report) {
        data.reportType = this.report;
      }
      if (this.reportStartTime) {
        data.reportStartTime = this.reportStartTime + " " + "00:00:00";
      } else {
        data.reportStartTime = "";
      }
      if (this.reportEndTime) {
        data.reportEndTime = this.reportEndTime + " " + "23:59:59";
      } else {
        data.reportEndTime = "";
      }
      this.getList(data);
    },
    // 重置
    Remake() {
      this.dataList = {};
      this.tags = [];
      // 开始时间
      this.reportStartTime = "";
      // 结束时间
      this.reportEndTime = "";
      // 模糊搜索
      // 报告名称操作人
      this.name = "";
      // 选择下拉 报告名称操作人
      this.seachTitle = "reportName";
      // 报告
      this.report = "";
      // 时间
      this.timer = [];
      this.getList();
    },
    handleClose(tag, tags) {
      tags.splice(tags.indexOf(tag), 1);
      if (tag.text == "报告名称" || tag.text == "操作人") {
        this.name = "";
      } else if (tag.text == "报告") {
        this.report = "";
      } else {
        this.timer = [];
        this.reportEndTime = "";
        this.reportStartTime = "";
      }
      this.Search();
    },
    handel() {
      this.$router.push({
        path: "/report/index/baogao",
      });
    },
    // 报告详情跳转
    handle(item) {
      if (item.reportType == "话题专项") {
        this.specialId = item;
        this.dialogVisible = true;
        return false;
      }
      // if (item.type == 0 || item.type == 1) {
      //   return false;
      // }
      // let routeUrl = this.$router.resolve({
      //   path: "/report/index/xiangqing",
      //   query: {
      //     info: encodeURIComponent(
      //       JSON.stringify({
      //         reportId: item.reportId,
      //         reportName: item.reportName,
      //         reportType: item.reportType,
      //         operator: item.operator,
      //         reportEndTime: item.reportEndTime,
      //         reportStartTime: item.reportStartTime,
      //         type: item.type
      //       })
      //     )
      //   }
      // });
      // window.open(routeUrl.href, "_blank");
    },
  },
};
</script>

<style scoped>
.organizationManagement {
  padding: 24px;
}

.organizationManagement_title {
  color: #1a1a1a;
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 24px;
}

.organizationManagement_top {
  background: #ffffff;
  border-radius: 4px;
}

.organizationManagement_top_content {
  padding: 16px 24px;
}

.el_font {
  margin-left: 32px;
  margin-right: 12px;
  color: #333333;
  font-weight: bold;
  font-size: 14px;
}

.organizationManagement_content {
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
  margin-top: 16px;
}

.organizationManagement_content_top {
  margin-bottom: 16px;
  overflow: hidden;
}

.allPeople {
  float: left;
  font-size: 16px;
  color: #333;
}

.export_z,
.xinzeng_z,
.exportIn_z {
  float: right;
}

.bianji,
.table_line,
.shanchu {
  display: inline-block;
  vertical-align: middle;
}

.table_line {
  width: 1px;
  height: 14px;
  background: #e4e6ec;
  margin: 0 16px;
}

.bianji {
  color: #2e59ec;
  padding: 0;
}

.shanchu {
  color: #ea3342;
  padding: 0;
}

.el_search {
  overflow: hidden;
}

.el_search_input {
  float: left;
}

.el_search_button {
  float: right;
  margin-right: 10px;
}

.name-wrapper {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.name-wrapper-color {
  cursor: pointer;
  color: #2e59ec;
}

.span-img {
  position: absolute;
  top: 0;
  left: 0;
}

.span-img img {
  width: 18px;
  height: 18px;
}

.chongzhi {
  color: #333333;
  width: 60px;
  font-size: 14px;
  height: 32px;
  box-sizing: border-box;
}
</style>
